<template>
	<div class="home">
		<c-hero @showModal="onToggleModal" />

		<section id="s-0" class="section">
			<div class="container">
				<div class="section-heading">
					<p>Start your love story</p>
					<h2 class="section-heading__title">We were joined</h2>
				</div>

				<div class="counter">
					<div class="counter__inner">
						<div class="row">
							<div v-for="(oCounter, index) in aCounters" :key="'c' + index"
								class="col-6 col-lg-3"
							>
								<div class="counter__item">
									<img :src="oCounter.img" :alt="oCounter.title" />
									<span>{{ oCounter.total }}</span>
									{{ oCounter.title }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="s-1" class="section section--bg">
			<div class="container">
				<div class="section-heading section-heading--light">
					<p>Join our community right now!</p>
					<h2 class="section-heading__title">Don`t waste your time</h2>
				</div>

				<div class="features">
					<div class="features__inner">
						<div class="row">
							<div v-for="(oFeature, index) in aFeatures" :key="'f' + index"
								class="col-12 col-lg-4"
							>
								<div
									:class="{'features__item--dark': oFeature.dark}"
									class="features__item"
								>
									<i>
										<img
											v-if="index !== 2"
											:src="oFeature.img"
											width="50"
											height="50"
											:alt="oFeature.title"
										/>
										<img
											v-else
											:src="oFeature.img"
											width="65"
											height="65"
											:alt="oFeature.title"
										/>
									</i>
									<div class="features__title">{{ oFeature.title }}</div>
									<p>{{ oFeature.content }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section">
			<div class="container-fluid">
				<div class="section-heading">
					<h2 class="section-heading__title">Online now:</h2>
				</div>

				<div class="profile">
					<div class="profile__filter">
						<span @click="onToggleModal">All</span>
						<span @click="onToggleModal">Female</span>
						<span @click="onToggleModal">Male</span>
					</div>

					<div class="profile__inner">
						<div class="row">
							<div v-for="oUser in aUsers" :key="oUser.id"
								class="col-6 col-md-4 col-lg-3 col-xl-2"
							>
								<div class="profile__item" @click="onToggleModal">
									<figure class="profile__img">
										<img :src="applyImgSrc(oUser.photo)" :alt="oUser.name + ' ' + oUser.age" />
									</figure>
									<span class="profile__status">online</span>
									<div class="profile__info">
										<span>{{ oUser.name }}</span>
										Age: {{ oUser.age }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section section--no-pt section--no-pb">
			<div class="section-heading">
				<h2 class="section-heading__title">Latest stories</h2>
			</div>

			<div class="post">
				<div class="post__inner">
					<div class="row no-gutters">
						<div v-for="(oPost, index) in aPosts" :key="'p' + index"
							class="col-12 col-lg-4"
							@click="onToggleModal"
						>
							<div class="post__item">
								<figure class="post__img">
									<img v-if="oPost.img" :src="oPost.img" alt="demo" />
								</figure>

								<div class="post__content"> 
									<span class="post__date">{{ oPost.date }}</span>

									<template v-if="oPost.content">
										<div class="post__title">{{ oPost.title }}</div>
										<p>{{ oPost.content }}</p>
										<span class="post__author">{{ oPost.author }}</span>
									</template>
									<template v-else>
										<span class="post__author">{{ oPost.author }}</span>
										<div class="post__title">{{ oPost.title }}</div>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<footer class="footer">
			<div class="container">
				<img class="footer__logos" src="@/assets/footer-logos.png" alt="logos" />
				<p>date69.com 2008-{{ (new Date()).getFullYear() }} &copy; All rights reserved</p>
			</div>
		</footer>

		<transition name="fade">
			<div v-show="!bHideModal" class="modal js-scrollbar-offset">
				<div class="modal__inner">
					<div class="container">
						<form-signup />
					</div>
				</div>

				<button class="modal__close" @click="onToggleModal" type="button"></button>
			</div>
		</transition>
	</div>
</template>

<script>
import axios from 'axios';

import scrolling from '@/utils/scrolling';

import Hero       from '@/components/Hero.vue';
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'home',

	components: {
		'c-hero': Hero,
		'form-signup': FormSignup
	},

	data: function ()
	{
		return {
			bHideModal: true,

			aUsers: [],
			aCounters: Object.freeze([
				{
					img: require('@/assets/counter-ico-1.svg'),
					total: 8130,
					title: 'Created profiles'
				},
				{
					img: require('@/assets/counter-ico-2.svg'),
					total: 3801,
					title: 'Girls'
				},
				{
					img: require('@/assets/counter-ico-3.svg'),
					total: 1254,
					title: 'Agree to date'
				},
				{
					img: require('@/assets/counter-ico-4.svg'),
					total: 2800,
					title: 'Done dating'
				}
			]),
			aFeatures: Object.freeze([
				{
					img: require('@/assets/feature-ico-1.svg'),
					title: 'Profiles',
					content: 'More than ten thousand girls from different parts of the world are waiting for you, their personal photo videos, as well as the opportunity to chat with them in video chat'
				},
				{
					dark: true,
					img: require('@/assets/feature-ico-2.svg'),
					title: 'Add profile',
					content: 'After registering on our resource, you will get unlimited access to viewing girls\' profiles'
				},
				{
					img: require('@/assets/feature-ico-3.svg'),
					title: 'Love stories',
					content: 'Tens of thousands of people have already found a mate for the evening, implemented real meetings with beautiful girls, this is not an escort, but girls interested in having a good time ...'
				}
			]),
			aPosts: Object.freeze([
				{
					date: 'June 18, 2021',
					author: 'Aleksandra Leyso',
					title: 'Wonderful experience.',
					content: 'I have not remembered for a long time that they showed so much attention to me! I did not have time to register, as I immediately met David. Now we are together and recommend Date69 to everyone.'
				},
				{
					img: require('@/assets/story-2.jpg'),
					date: 'August 6, 2021',
					author: 'Ashley Minx',
					title: 'Would you like to play with me?'
				},
				{
					img: require('@/assets/story-3.jpg'),
					date: 'August 10, 2021',
					author: 'Emma Weavey',
					title: 'My fantasies drive me crazy'
				}
			])
		}
	},

	created: async function ()
	{
		this.aUsers = await this.fetchUsers();

		await this.sendPidAndSid();
	},

	methods: {
		onToggleModal: function (_value)
		{
			if (this.bHideModal)
			{
				scrolling.hide();
			}
			else
			{
				scrolling.restore();
			}

			this.bHideModal = !this.bHideModal;
		},

		fetchUsers: async function ()
		{
			var options = {
					method: 'post',
					url: '/api/?action=get_profiles'
				};

			try
			{
				var oResp = await axios(options);

				return Object.values(oResp.data);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}
		},

		sendPidAndSid: async function ()
		{
			if (process.env.NODE_ENV === 'development') return;


			var oSearchParams = new URL(document.location).searchParams,
				sData =
						'pid=' + oSearchParams.get('pid') +
						'&sid=' + oSearchParams.get('sid') +
						'&com1=' + oSearchParams.get('com1') +
						'&com2=' + oSearchParams.get('com2') +
						'&com3=' + oSearchParams.get('com3') +
						'&referer=' + document.referrer,
				options = {
					method: 'post',
					url: '/api/?action=send_partner_info',
					data: sData
				};

			try
			{
				await axios(options);
			}
			catch (e)
			{
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		applyImgSrc: function (_sUrl)
		{
			return '//storage.victoriyaclub.com' + _sUrl;
		}
	}
}
</script>

<style lang="scss">
@import '../styles/style';

.counter
{
	margin-top: 100px;

	&__inner
	{
		margin-bottom: -50px;
	}

	&__item
	{
		position: relative;
		margin-bottom: 50px;
		padding-top: 55px;
		line-height: 1;
		font-size: 2rem;
		font-weight: 700;
		text-align: center;
		color: #333;

		img
		{
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}

		span
		{
			display: block;
			margin-bottom: 25px;
			font-size: 5rem;
			color: #AE1E1E;
		}
	}

	@media (min-width: 992px)
	{
		.col,
		[class*=" col-"],
		[class^=col-]
		{
			&::before
			{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				border-left: 1px solid rgba(#828282, 0.33);
			}

			&:first-child
			{
				&::before
				{
					content: none;
				}
			}
		}
	}
}

#s-0
{
	overflow: hidden;

	&::before
	{
		content: '';
		position: absolute;
		top: 45px;
		right: -13%;
		width: 120px;
		height: 88px;
		background: #FD2B2B;
		border-radius: 30px;
		transform: rotate(40deg);
		transform-origin: left top;
		z-index: -1;
	}

	@media (min-width: 768px)
	{
		&::before
		{
			top: 10px;
			right: 29%;
			width: 216px;
			height: 158px;
			border-radius: 50px;
		}
	}
}

#s-1
{
	background: url('~@/assets/bg.jpg') no-repeat center;
	background-size: cover;
}

.features
{
	margin-top: 90px;

	&__inner
	{
		margin-bottom: -30px;
	}

	&__item
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 400px;
		min-height: 360px;
		margin: auto;
		margin-bottom: 30px;
		padding: 15px;
		line-height: 1.5;
		font-size: 1.4rem;
		text-align: center;
		color: #AE1E1E;
		background-color: #FFF;
		border-radius: 50px;
		overflow: hidden;

		&--dark
		{
			color: #FFF;
			background-color: #333;
		}

		i
		{
			margin-bottom: 25px;
			height: 65px;
			font-size: 0;

			&::before
			{
				content: '';
				display: inline-block;
				vertical-align: bottom;
				height: 100%;
			}
		}

		p
		{
			max-width: 280px;
			min-height: 105px;
			margin: 0 auto;
		}
	}

	&__title
	{
		margin-bottom: 20px;
		line-height: 1;
		font-size: 2.5rem;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.profile
{
	margin: 40px auto 0;
	max-width: 1720px;

	.row
	{
		margin-left: -10px;
		margin-right: -10px;
	}

	.col,
	[class*=" col-"],
	[class^=col-]
	{
		padding-left: 10px;
		padding-right: 10px;
	}

	&__filter
	{
		margin-bottom: 40px;
		line-height: 1;
		font-size: 2rem;

		span
		{
			margin-left: 30px;
			cursor: pointer;

			&:first-child
			{
				margin-left: 0;
				font-weight: 700;
				color: #AD1E1E;
			}

			&:hover
			{
				color: #AD1E1E;
			}
		}
	}

	&__inner
	{
		margin-bottom: -20px;
	}

	&__item
	{
		position: relative;
		margin-bottom: 20px;
		line-height: 1;
		color: #FFF;
		background-color: #565453;
		cursor: pointer;
		overflow: hidden;
		border-radius: 20px;
	}

	&__img
	{
		position: relative;
		overflow: hidden;

		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(280/270);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__status
	{
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 0 10px;
		line-height: 29px;
		font-size: 1.4rem;
		font-weight: 600;
		text-align: center;
		background-color: #AE2020;
		border-radius: 5px;
	}

	&__info
	{
		padding: 15px;
		padding-left: 50px;
		font-size: 1.4rem;
		background: url('~@/assets/webcam.svg') no-repeat 15px 20px;

		span
		{
			display: block;
			padding-bottom: 5px;
			font-size: 1.6rem;
		}
	}

	@media (min-width: 576px)
	{
		&__status
		{
			top: 18px;
		}

		&__info
		{
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 70px;
			font-size: 1.6rem;
			background-position: 25px 20px;

			span
			{
				font-size: 1.8rem;
			}
		}
	}
}

.post
{
	margin: 55px auto 0;
	max-width: 1720px;

	&__inner
	{

	}

	&__item
	{
		position: relative;
		background-color: #AE1E1E;
		overflow: hidden;
	}

	&__img
	{
		&::before
		{
			content: "";
			display: block;
			padding-top: percentage(670/570);
		}

		img
		{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__content
	{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 60px 40px;
		line-height: 1.2;
		color: #FFF;
		cursor: pointer;
	}

	&__date
	{
		margin-bottom: auto;
	}

	&__author
	{
		margin-bottom: 18px;
		font-size: 1.8rem;
		text-transform: uppercase;

		&::before
		{
			content: 'by ';
			text-transform: none;
		}

		&:last-child
		{
			margin-bottom: 0;
		}
	}

	&__title
	{
		max-width: 300px;
		font-size: 3rem;
		font-weight: 700;
	}

	p
	{
		max-width: 360px;
		margin-top: 20px;
		margin-bottom: auto;
		line-height: 1.6;
	}
}

.footer
{
	padding-top: 50px;
	padding-bottom: 25px;
	line-height: 1;
	font-size: 1.2rem;
	text-align: center;
	color: #F2F2F2;
	background-color: #565453;

	&__logos
	{
		margin-bottom: 30px;
	}
}


.fade-enter-active,
.fade-leave-active {
	transition: opacity 300ms;
}

.fade-enter,
.fade-leave-to {
	transition: opacity 300ms;
	opacity: 0;
}

.modal
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background: url('~@/assets/modal-bg.jpg') no-repeat 30% center;
	background-size: cover;
	overflow: auto;
	z-index: 10;
	-webkit-backface-visibility: hidden;

	&::before
	{
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 0;
		height: 100%;
	}

	&__inner
	{
		position: relative;
		display: inline-block;
		vertical-align: middle;
		min-width: 100%;
		padding: 30px 0;
	}

	&__close
	{
		position: absolute;
		top: 20px;
		right: 20px;
		padding: 30px 30px 0 0;
		background: #AE1E1E;
		border: none;
		border-radius: 50%;
		cursor: pointer;

		&::before,
		&::after
		{
			content: "";
			position: absolute;
			background: #FFF;
			transform: rotate(45deg);
		}

		&::before
		{
			width: 2rem;
			height: 4px;
			top: 50%;
			left: 50%;
			margin-top: -2px;
			margin-left: -1rem;
		}

		&::after
		{
			width: 4px;
			height: 2rem;
			left: 50%;
			top: 50%;
			margin-top: -1rem;
			margin-left: -2px;
		}

		@media (min-width: 768px)
		{
			padding: 55px 55px 0 0;

			&::before
			{
				width: 2.8rem;
				margin-left: -1.4rem;
			}

			&::after
			{
				height: 2.8rem;
				margin-top: -1.4rem;
			}
		}
	}

	.form-signup
	{
		margin-left: auto;
		margin-right: auto;
		background-color: #676767;

		@media (min-width: 992px)
		{
			margin-right: 0;
		}
	}
}
</style>
